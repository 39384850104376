import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { TableFilters } from '../../../../../utilities/table-filters';
import { ContractService } from '../../../contracts/services/contract.service';
import { Fixation } from '../../models/fixation.model';

@Component({
  selector: 'fixations-table',
  templateUrl: './fixations-table.component.html',
  styleUrls: ['./fixations-table.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FixationsTableComponent),
      multi: true,
    },
  ],
})
export class FixationsTableComponent extends TableFilters implements OnInit, ControlValueAccessor, OnDestroy {
  /** List of columns of the table to hide. */

  @Input() public hideColumns: string[] = [];
  @Input() public selectedFilters: FilterSetComponent;
  /** Whether the selection checkboxes are shown or not. */
  @Input() public enableSelection: boolean;
  @Input() public showRequestedFixations: boolean = false;

  @Output('load') readonly onLoad = new EventEmitter();

  public viewMode: string = 'fixations';
  public fixations: Fixation[];
  /** The language currently used. */
  public currentLang: string;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public predefinedFilters: boolean;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private contractService: ContractService,
    private translateService: TranslateService
  ) {
    super(route, router);
    this.currentLang =
      this.translateService.currentLang === 'es'
        ? undefined
        : this.translateService.currentLang;
  }

  ngOnInit(): void {
    const { configuration } = this.company.market;

    if (configuration.imported_data.collections &&
      configuration.imported_data.collections.includes('fixations')) {
      if (this.filters) {
        this.predefinedFilters = true;
        this.loadData();
      } else {
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }
  }

  public hiddenColumnsCount(columns: string[]): number {
    let count: number = 0;
    columns.forEach((key) => {
      if (this.hideColumns.includes(key)) count++;
    });
    return count;
  }

  private loadData(): void {
    this.loading = true;
    this.selected = [];
    this.subscriptions.push(this.contractService.getFixations(this.company.id, this.filters).subscribe((response) => {
      this.dataLoaded(response.body, parsePagination(response.headers));
    }));
  }

  private dataLoaded(data: Fixation[], pagination?: Pagination): void {
    this.fixations = data;
    this.loading = false;

    this.onLoad.emit({
      data: this.fixations,
      pagination: pagination,
    });
  }

  // ngModel
  private _value: Fixation[] = [];
  public get selected(): Fixation[] {
    return this._value;
  }
  public set selected(v: Fixation[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
