import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { User } from '../../../../../auth/models/user.model';
import { LoginService } from '../../../../../auth/services/login.service';
import { Company } from '../../../../../models/company.model';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { ImporterService } from '../../services/importer.service';

/**
 * Component that manages the import of data by uploading files only in
 * supported formats.
 *
 * ### Related UI components:
 * - [[ImportedDataComponent]]
 */
@Component({
  selector: 'importer',
  templateUrl: './importer.component.html',
  styleUrls: ['./importer.component.scss']
})
export class ImporterComponent implements OnInit, OnDestroy {

  @ViewChild('dropArea', { static: true }) private readonly dropArea: ElementRef;
  @ViewChild('dropLegend', { static: true }) private readonly dropLegend: ElementRef;

  @Input() public company: Company;

  /**
   * Specify what file types the user can pick from the file input dialog box.
   *
   * Works as
   * [[https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept|HTML attribute: accept]].
   */
  public accept: string = ".txt, .xls, .xlsx";
  public collapsePanel: any;
  public errors: {
    file: string,
    results: {
      class: string,
      index: number,
      input: string,
      data: any,
      errors: any
    }[]
  }[];
  public errors_per_page: number = 10;
  public fatalError: any;
  public loadingStatuses: boolean;
  public max_errors_per_file: number[];
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public showFields: any;
  public showFile: boolean[];
  public statusData: any[];
  /** Current [[User]]. */
  public user: User;

  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private loginService: LoginService,
    private translateService: TranslateService,
    private importerService: ImporterService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));

    this.setupDrop(this.dropArea.nativeElement, this.dropLegend.nativeElement);
    this.getStatuses();
  }

  private setupDrop(el: HTMLElement, lg: HTMLElement): void {
    const scope = this,
      dragger = function (e) {
        e.stopPropagation();
        e.preventDefault();
        displayDrag();
      },
      drop = function (e) {
        dragger(e);

        // Triggered by drop
        // Prevent to drag more files when the max files number is reached
        if (checkAccept(e.dataTransfer.files)) scope.uploadFiles(e.dataTransfer.files);
        // }
        removeDrag();
      },
      displayDrag = function (): void {
        lg.className = 'is-dragging';
      },
      removeDrag = function (): void {
        lg.className = '';
      },
      checkAccept = function (files: FileList): boolean {
        if (scope.accept === "") return true; // Empty accept accepts all

        let allGood = true;
        const specifiers = scope.accept.split(',');

        for (let i = 0; i < files.length && allGood; i++) { // runs as long as allGood
          let isValid = false,
            file = files[i];

          for (let j = 0; j < specifiers.length && !isValid; j++) { // runs as long as is NOT valid
            const specifier = specifiers[j].trim().toLowerCase();

            if (specifier.indexOf('.') === 0) {
              // Extension (case insensitive)
              if (file.name.toLowerCase().endsWith(specifier)) isValid = true; // This file is valid
            } else {
              // MIME type string
              let matcher = new RegExp(specifier, "g");
              if (matcher.test(file.type)) isValid = true; // This file is valid
            }
          }

          if (!isValid) allGood = false; // This file is NOT valid
        }

        return allGood;
      };

    el.addEventListener("dragenter", dragger, false);
    el.addEventListener("dragover", dragger, false);
    el.addEventListener("drop", drop, false);
    el.addEventListener("dragleave", removeDrag, false);
    el.addEventListener("dragend", removeDrag, false);
  }

  /** Files input field change listener. */
  public changeFile(event): void {
    // Triggered by input component
    this.uploadFiles(event.target.files);
    event.target.value = ""; // Reset the input
  }

  private uploadFiles(files: FileList): void {
    this.processing = true;

    this.subscriptions.push(this.importerService.import(this.company.id, files).subscribe({
      next: response => {
        if (response.length) this.parseErrors(response);

        this.getStatuses(); // Update status information
        this.processing = false;
      },
      error: error => {
        this.processing = false;

        if (error.status === 400) {
          const inputs = error.error.message.inputs;

          const errors = Object.keys(inputs)
            // .filter(k => inputs[k][0].errors.includes('INVALID_FILETYPE'))
            .map(k => {
              const file = inputs[k][0].file;

              return {
                file,
                results: [
                  {
                    index: 0,
                    input: this.translateService.instant('IMPORTER.NOT_INPUT_AVAILABLE'),
                    error: inputs[k][0].errors.join(', ')// this.translateService.instant('IMPORTER.INVALID_FILETYPE')
                  }
                ]
              }
            });

          if (errors.length > 0) {
            this.parseErrors(errors);
          }
        } else this.fatalError = error;

        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  /**
   * ### Expected format:
   *
   * ``` javascript
   * data = [
   *   file: string
   *   results: [
   *     {
   *       class: string
   *       index: number
   *       input: string
   *       error?: string
   *       data?: {}
   *       errors?: {}
   *     }
   *   ]
   * ]
   * ```
   */
  private parseErrors(data: any[]): void {
    this.max_errors_per_file = new Array(data.length).fill(this.errors_per_page);
    this.showFields = {};
    this.showFile = new Array(data.length).fill(true);
    this.collapsePanel = {};
    this.errors = data;
  }

  /** Retrieves imports status information. */
  public getStatuses(): void {
    this.loadingStatuses = true;

    this.subscriptions.push(this.importerService.status(this.company.id).subscribe(response => {
      this.statusData = response.body;
      this.loadingStatuses = false;
    }));
  }

  /** Reset error messages. */
  public reset(): void {
    this.fatalError = undefined;
    this.errors = undefined;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
