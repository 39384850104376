<contract-modal #contractModal [company]="company"></contract-modal>

<manage-request-fixation-modal #manageFixationRequestModal [company]="company" [currencies]="currencies"></manage-request-fixation-modal>

<ng-template #noData>
  <!-- No data -->
  <div class="no-data">
    <i class="material-symbols-rounded">not_interested</i>
    {{ 'GLOBAL.NO_DATA'|translate }}
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div class="loading" *ngIf="processing">
    <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
  </div>
</ng-template>

<ng-container *ngIf="!processing; else loadingSpinner">
  <div class="horizontal-scroller">

    <div *ngIf="requests?.length; else noData" [class.tableContainer]="compact">

      <table class="table table-condensed" sortable #tableSortable="tableSortable" [variableInUrl]="true">
        <thead>
          <tr class="tr-align-center">
            <th class="sap" sortBy="date">
              {{ "GLOBAL.DATE" | translate }}
            </th>
            <th class="sap" *ngIf="!compact">
              {{ "GLOBAL.CONTRACT" | translate }}
            </th>
            <th>
              {{ "SLOTS.REQUEST_SLOT_MODAL.APPLICANT" | translate }}
            </th>
            <th>
              {{ "SLOTS.REQUEST_SLOT_MODAL.RECIPIENT" | translate}}
            </th>
            <th class="sap" *ngIf="!compact">
              <abbr title="{{ 'GLOBAL.OBSERVATIONS'|translate }}">{{ 'GLOBAL.OBSERVATIONS_ABBR'|translate }}</abbr>
            </th>
            <th *ngIf="!compact">
              {{ "GLOBAL.PRODUCT" | translate}}
            </th>
            <th class="sap" sortBy="quantity">
              {{ "GLOBAL.QUANTITY_ABBR" | translate}}
            </th>
            <th class="sap" sortBy="price">
              {{ "GLOBAL.PRICE" | translate}}
            </th>
            <th class="sap">
              {{ "GLOBAL.STATUS" | translate }}
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody class="scrollit">
          <tr class="tr-align-center" *ngFor="let item of requests"
            [class.rejected]="item.status.id === 4"
            [class.accepted]="item.status.id === 3">
            <td class="sap small">
              <date-md [date]="item.created_at" >
              </date-md>
            </td>
            <td class="sap small" *ngIf="!compact">
              <a (click)="contractModal.show(item.contract.id)">{{ item.contract.reference }}</a>
            </td>
            <td>
              <company-info [company]="item.company" [compact]="compact"></company-info>
            </td>
            <td>
              <company-info [company]="item.recipient" [compact]="compact"></company-info>
            </td>
            <td class="sap text-center" *ngIf="!compact">
              <i tooltip="{{ item.observations }}" placement="right" class="text-muted material-symbols-rounded"
                *ngIf="item.observations">comment</i>
            </td>
            <td class="dlb drb text-left sap" *ngIf="!compact">
              {{item.contract?.product.name}}
            </td>
            <td class="dlb drb text-right sap">
              <span *ngIf="item.quantity; else none">
                {{ (item.quantity.value|number:'1.0-0':currentLang) +' ' + item.quantity?.unit?.abbrev }}
              </span>
            </td>
            <td class="dlb drb text-right sap">
              <price-display *ngIf="item.price" [price]="item.price" [market]="company.market" [withoutYear]="true"></price-display>
            </td>
            <td class="sap"
            [class.text-success]="item.status.id === 3"
            [class.text-danger]="item.status.id === 4"
            [class.text-info]="item.status.id === 1 || item.status.id === 2">

              {{ isWaitingResponse(item) ?
                ('FIXATIONS.REQUEST_STATUS.WAITING_COUNTERPART' | translate):
                ('FIXATIONS.REQUEST_STATUS.' + item.status.name.toUpperCase() | translate)
              }}
            </td>
            <td class="sap">
              <ng-container>
                <button
                  *ngIf="item && !isClosedRequest(item) && !isWaitingResponse(item)"
                  type="button"
                  [disabled]="readonlyUser "
                  class="btn btn-agree"
                  (click)="openManageFixationRequestModal(item)">
                  {{ 'FIXATIONS.REQUEST_MODAL.REPLY' | translate }}
                  <i class="material-symbols-rounded">swap_vert</i>
                </button>
              </ng-container>
            </td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>
