import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';

import { environment } from '../../../../../../environments/environment';
import { Account } from '../../../../../auth/models/account.model';
import { Company } from '../../../../../models/company.model';
import { Pagination } from '../../../../../models/pagination.model';
import { CompanyService } from '../../../../../services/company.service';
import { ComponentCommService } from '../../../../../services/component-comm.service';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { ContractsTableComponent } from '../../../contracts/components/contracts-table/contracts-table.component';

class Tab {
  id?: number;
  slug: string;
  title: string;
  queryParams?: object;

  constructor(data: Partial<Tab> = {}) {
    Object.assign(this, data);
  }
}

@Component({
  selector: 'app-imported-data',
  templateUrl: './imported-data.component.html',
  styleUrls: ['./imported-data.component.scss']
})
export class ImportedDataComponent implements OnInit, OnDestroy {

  @ViewChild('filterSet', { static: true }) public readonly selectedFilters: FilterSetComponent;
  @ViewChild('contractTable') private readonly contractTable: ContractsTableComponent;

  public exportableTable;

  private collectionSlug: string;
  private subscriptions: Subscription[] = [];
  private account: Account;

  public collections: Tab[];
  public company: Company;
  public isImporter: boolean;
  public syngentaImporter: boolean;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public collectionResults: any = {};
  public selectedCollection: any;

  public paginationData: Pagination;
  public selection: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService,
    private componentComm: ComponentCommService
  ) { }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'GLOBAL.CONTRACTS' });

    this.subscriptions.push(
      combineLatest({
        company: this.companyService.watch(),
        account: this.companyService.watchAccount()
      }).subscribe(({ company, account }: { company: Company, account: Account }) => {
        if (company && account) {
          this.company = company;
          this.account = account;
          // TODO: This validation is a HOT FIX, delete as soon as possible.
          if (this.company.id === 450) { // Kick ADAMA(450)
            this.router.navigateByUrl('/companies');
          }
          // Kick user if module is not enabled
          if (!this.company.hasModule('contracts') || this.company.market.configuration.imported_data.length === 0 || !environment.modules.imported_data) {
            this.router.navigateByUrl('/companies');
          } else {
            this.isImporter = this.company.hasModule('importer');
            // Syngenta
            this.syngentaImporter = this.isImporter && (!environment.production || this.company.id == 401);
            this.loadCollections();
          }
        };
      })
    )

    this.subscriptions.push(this.route.paramMap.subscribe(params => {
      this.paginationData = undefined;
      this.collectionSlug = params.get('collection');
      this.setCollection();
    }));
  }

  private loadCollections(): void {
    this.loading = true;

    const { collections } = this.company.market.configuration.imported_data;
    const { modules } = environment;

    let ids = 0;
    const menu: Tab[] = [];

    const enableTab = (slug) => {
      // Check for environment module and market collection
      return this.handleCompanyModulesByRepresentativeRole(slug) && modules[slug] && collections.includes(slug);
    };

    const addTab = function (title: string, slug: string, queryParams?) {
      const tab = new Tab({
        id: ids++,
        title: title,
        slug: slug,
        queryParams: queryParams
      });
      // Populates menu
      if (enableTab(slug)) menu.push(tab);

      return tab;
    };
    // Order is relevant
    addTab('GLOBAL.CONTRACTS', 'contracts');
    addTab('CONTRACTS.APPLICATIONS', 'contract_applications');
    addTab('LIQUIDACIONES.TITLE', 'liquidaciones');
    addTab('FIXATIONS.TITLE', 'fixations');
    // addTab('WAYBILLS.TITLE', 'waybills');
    addTab('CPE.TITLE', 'cpe', { order_by: '-fecha_inicio_estado' });
    addTab('INVOICES.TITLE', 'invoices');
    // addTab('TRUCKS.TITLE', 'unloads');

    // Remove this check once is operational
    if (!environment.production) addTab('FUTURES.TITLE', 'futures');

    if (this.isImporter) menu.push(addTab('IMPORTER.TITLE', 'importer'));

    this.collectionsLoaded(menu);
  }

  /**
   * Handle Imported data modules by company and the manager role account
   * It is a type of temporary middleware
   */
  private handleCompanyModulesByRepresentativeRole(slug: string): boolean {
    const modules = environment.visibilityByRepresentativeRoles?.[this.company.id];

    if (this.account.isRepresentative && modules) {
      if (this.account.is.admin) return true;

      return modules[this.account.role.slug].includes(slug) ?? false;
    } else {
      return true;
    }
  }

  private collectionsLoaded(data: Tab[]): void {
    this.collections = data;
    this.setCollection();

    this.loading = false;
  }

  private setCollection(): void {
    if (this.collections) {
      // Reset summary
      this.collectionResults[this.collectionSlug] = undefined;
      this.selection = [];
      // Set current collection
      this.selectedCollection = this.collections.filter(col => col.slug === this.collectionSlug)[0];

      if (!this.selectedCollection) {
        // Redirect to new Contracts page
        if (this.collectionSlug === 'contracts') {
          this.router.navigate(['/company/', this.company.id, 'contracts'], { queryParams: this.route.snapshot.queryParams });
        } else {
          // Default to first
          this.navigateTo(this.collections[0].slug);
        }
      }
    }
  }

  public navigateTo(slug: string, queryParams = { order_by: '-date' }): void {
    this.selectedCollection = undefined;
    this.exportableTable = undefined;

    setTimeout(() => {
      this.router.navigate(['company', this.company.id, 'imported-data', slug], {
        queryParams
      });
    });
  }

  public collectionLoaded(slug: string, e): void {
    this.paginationData = e.pagination;
    this.collectionResults[slug] = e.data ? e.data.length : undefined;

    switch (slug) {
      case 'contracts':
        this.exportableTable = this.contractTable;
        break;

      default:
        this.exportableTable = undefined;
        break;
    }
  }

  public refresh(): void {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    });
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
