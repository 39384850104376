<div class="loading" *ngIf="processing">
  <spinner message="{{ 'GLOBAL.PROCESSING'|translate }}"></spinner>
</div>

<div [class.hidden]="processing || errors || fatalError" id="importer" #dropArea>
  <div #dropLegend id="dropLegend">
    <div>{{ 'MESSENGER.DROP_HERE'|translate }}</div>
  </div>

  <div>{{ 'IMPORTER.SELECT_FILES'|translate }}</div>
  <small>{{ 'IMPORTER.ACCEPTED_FORMATS'|translate }} {{ accept }}</small>

  <div class="attach-div">
    <input type="file" [accept]="accept" name="attach" id="attachInput" (change)="changeFile($event)" multiple="true">
    <label for="attachInput" class="btn btn-agree" [class.disabled]="processing">
      <i class="material-symbols-rounded">cloud_upload</i>
      {{ 'FILE_INPUT.SELECT_FILES'|translate }}
    </label>
  </div>

  <small class="text-muted">{{ 'IMPORTER.DROP_HELP'|translate }}</small>
</div>

<ng-container *ngIf="errors || fatalError">
  <div class="ag-header sticky">
    <div class="ag-header-title">
      <div *ngIf="errors" class="summary" [innerHtml]="'IMPORTER.ERRORS_SUMMARY'|translate:{files: (errors|pluck:'results'|flatten).length, errors: errors.length}"></div>
    </div>
    <div class="ag-header-actions">
      <button type="button"
      class="btn btn-agree" [disabled]="processing" (click)="reset()">
        <i class="material-symbols-rounded hidden-xs hidden-sm">undo</i> {{ 'IMPORTER.TRY_AGAIN'|translate }}
      </button>
    </div>
  </div>
  <div *ngIf="fatalError" class="well well-sm">
    <ngx-json-viewer [json]="fatalError" [expanded]="false"></ngx-json-viewer>
  </div>
  <div *ngFor="let file of errors; let file_index = index" class="file-container">
    <table class="full-width">
      <tbody>
        <tr class="tr-align-center">
          <td>
            <h4>
              {{ 'IMPORTER.SOURCE_FILE'|translate }}: <samp>{{ file.file }} </samp>
              <small [innerHtml]="'IMPORTER.ERRORS'|translate:{errors: file.results.length}"></small>
            </h4>
          </td>
          <td class="text-right">
            <button type="button"
            class="btn btn-link btn-sm" (click)="showFile[file_index] = !showFile[file_index]">
              <i class="glyphicon" [class.glyphicon-minus]="showFile[file_index]" [class.glyphicon-plus]="!showFile[file_index]"></i>
              {{ (showFile[file_index] ? 'IMPORTER.HIDE_FILE_DETAILS' : 'IMPORTER.SHOW_FILE_DETAILS')|translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <ng-container *ngIf="showFile[file_index]">
      <ng-container *ngFor="let error of file.results; let error_index = index">
        <div class="panel panel-danger" *ngIf="error_index < max_errors_per_file[file_index]">
          <div class="panel-heading" (click)="collapsePanel[file_index+'_'+error_index] = !collapsePanel[file_index+'_'+error_index]">
            <table class="full-width">
              <tbody>
                <tr>
                  <td>
                    <b>{{ 'IMPORTER.SOURCE_FILE'|translate }}:</b>
                    <samp> {{ file.file }} </samp>
                    <small [innerHtml]="'IMPORTER.ERROR_INDEX'|translate:{index: error_index + 1}"></small>
                  </td>
                  <td class="text-right">
                    <b>{{ 'IMPORTER.LINE'|translate }}:</b>
                    <samp> {{ error.index }}</samp>
  
                    <button type="button"
                    class="btn btn-link btn-xs">
                      <span class="material-symbols-rounded text-danger">{{ collapsePanel[file_index+'_'+error_index] ? 'unfold_more' : 'unfold_less'}}</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="panel-body" [collapse]="collapsePanel[file_index+'_'+error_index]" [isAnimated]="true">
            <h5>{{ 'IMPORTER.INPUT'|translate }}</h5>
            <div class="well well-sm"><samp>{{ error.input }}</samp></div>
            <div *ngIf="error.error" class="text-danger">
              <b>{{ 'IMPORTER.ERROR'|translate }}:</b>
              {{ error.error }}
            </div>
            <ng-container *ngIf="error.data && error.errors">
              <h5>{{ 'IMPORTER.DETAILS'|translate }}
                <button type="button"
                class="btn btn-link btn-sm" (click)="showFields[file_index+'_'+error_index] = !showFields[file_index+'_'+error_index]">
                  <i class="glyphicon" [class.glyphicon-minus]="showFields[file_index+'_'+error_index]" [class.glyphicon-plus]="!showFields[file_index+'_'+error_index]"></i>
                  {{ (showFields[file_index+'_'+error_index] ? 'IMPORTER.SHOW_ERROR_FIELDS' : 'IMPORTER.SHOW_ALL_FIELDS')|translate }}
                </button>
              </h5>
              <table class="table table-condensed table-striped table-hover"
              [sortable]="error.data|keyvalue" #tableErrors="tableSortable">
                <thead>
                  <tr>
                    <th sortBy="key">{{ 'TRACK_RECORD.FIELD'|translate }}</th>
                    <th sortBy="value">{{ 'IMPORTER.VALUE'|translate }}</th>
                    <th>{{ 'IMPORTER.ERROR'|translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of tableErrors.collection">
                    <ng-template #fieldOk>
                      <span class="material-symbols-rounded text-success">check_circle_outline</span>
                    </ng-template>
                    <tr *ngIf="error.errors[item.key] || showFields[file_index+'_'+error_index]">
                      <th>{{ item.key }}</th>
                      <td><samp>{{ item.value }}</samp></td>
                      <td>
                        <span *ngIf="error.errors[item.key] else fieldOk" class="text-danger">{{ error.errors[item.key] }}</span>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <button type="button"
      *ngIf="file.results.length > max_errors_per_file[file_index]" class="btn btn-link btn-block"
      (click)="max_errors_per_file[file_index] = max_errors_per_file[file_index] + errors_per_page">{{ 'IMPORTER.SHOW_MORE_ERRORS'|translate }}</button>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="statusData">
  <div class="flex-center-distributed">
    <h5>{{ 'IMPORTER.STATUSES'|translate }}</h5>
    <button type="button"
    class="btn btn-link btn-xs"
    [disabled]="loadingStatuses"
    (click)="getStatuses()"><span class="material-symbols-rounded">refresh</span></button>
  </div>
  <div class="horizontal-scroller">
    <div class="table-sticky-headers">    
      <table class="table table-condensed table-hover"
      sortable defaultSortBy="-id" #tableStatus="tableSortable">
        <thead>
          <tr>
            <th class="sap" sortBy="id">#</th>
            <th class="sap" sortBy="created_at">{{ 'AUCTION_VIEW.MODULE.HISTORY.TIME'|translate }}</th>
            <th sortBy="file_name">{{ 'IMPORTER.SOURCE_FILE'|translate }}</th>
            <th sortBy="user.name">{{ 'SIGNATURES.USER'|translate }}</th>
            <th class="sap" sortBy="updated_at">{{ 'IMPORTER.UPDATED'|translate }}</th>
            <th class="sap"></th>
            <th class="sap" sortBy="status.id">{{ 'GLOBAL.STATUS'|translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of statusData | orderBy: tableStatus.sortBy" class="tr-align-center">
            <td class="sap text-muted small text-right"><samp>{{ file.id }}</samp></td>
            <td class="sap">
              <ag-timestamp [date]="file.created_at"></ag-timestamp>
            </td>
            <td>
              <samp>{{ file.file_name }}</samp>
            </td>
            <td>
              <b
              *ngIf="file.user"
              [class.highlighted-text]="user?.id === file.user.id" title="{{ file.user.email }}">{{ file.user.name }} {{ file.user.last_name}}</b>
            </td>
            <td class="sap">
              <ag-timestamp [date]="file.updated_at"></ag-timestamp>
            </td>
            <td class="sap text-center"
            [class.text-success]="file.status.id === 5"
            [class.text-danger]="[2, 7].includes(file.status.id)">
              <span class="material-symbols-rounded text-success"
              *ngIf="[5].includes(file.status.id)">done</span>
              <span class="material-symbols-rounded text-danger"
              *ngIf="[2, 7].includes(file.status.id)">report</span>
              <span class="material-symbols-rounded text-muted animated live"
              *ngIf="[3, 4, 6].includes(file.status.id)">cached</span>
            </td>
            <td class="sap text-muted small"
            [class.text-success]="file.status.id === 5"
            [class.text-danger]="[2, 7].includes(file.status.id)"
            [innerHtml]="'IMPORTER.STATUS.'+file.status.id|translate"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>