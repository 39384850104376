<button type="button" class="btn btn-link" (click)="open()" [disabled]="processing">
  <i class="material-symbols-rounded">upload_file</i>
  <span class="hidden-xs">{{'CONTRACTS.IMPORT.BUTTON'|translate}}</span>
</button>

<ng-template #importModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{'CONTRACTS.IMPORT.BUTTON'|translate}}</h4>
  </div>
  <div class="modal-body">
    <form #importFileForm="ngForm" (ngSubmit)="importFileForm.valid">
      <div class="row">
        <div class="col-xs-12">
          <p [innerHTML]="'CONTRACTS.IMPORT.DESCRIPTION'|translate"></p>
          <div #importer class="form-group">
            <!-- <file-input
            drop-area="#importer"
            [(ngModel)]="importFileForm.file"
            name="file" inputId="file"
            [max-size]="maxSize" 
            [accept]="accept"
            [disabled]="processing" required></file-input> -->

            <ag-file-manager
            [(ngModel)]="importFileForm.source"
            [disabled]="processing"
            name="source"
            [required]="true"
            [max-files]="1"
            [max-size]="maxSize"
            [accept]="accept"
            [company]="company"
            autocomplete="off"
            [due-date]="dueDate"
            label="Contratos"
            type="contracts-import"></ag-file-manager>
          </div>
        </div>
      </div>
    </form>
    <alert type="danger" *ngIf="errorMessage">
      <span [innerHTML]="errorMessage"></span>
    </alert>
  </div>
  <!-- Footer -->
  <div class="modal-footer">
    <button (click)="closeModal()" type="button" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button (click)="submit(importFileForm)" type="submit" form="authorization-form" class="btn btn-agree"
      [disabled]="processing || importFileForm.invalid">{{'FINTECH.FIELDS_TABLE.IMPORT'|translate}}</button>
  </div>
</ng-template>