<!-- <ag-invoices-modals #invoicesModals [company]="company"></ag-invoices-modals> -->
<ag-file-preview #filePreviewer></ag-file-preview>

<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
</div>

<ng-container *ngIf="!loading">
  <div class="horizontal-scroller">
    <div class="table-sticky-headers">
      <table class="table table-condensed" sortable #tableSortable="tableSortable" [variableInUrl]="!predefinedFilters"
        AgMultiselection [(ngModel)]="selected">
        <thead>
          <tr class="tr-align-center">
            <!-- Checkbox -->
            <th class="sap micro" *ngIf="enableSelection &&
            invoices?.length > 0">
              <input type="checkbox" AgSelectall
              id="select-all-toggle"
              class="show-onhover no-margin"
              tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
              container="body" [adaptivePosition]="false">
            </th>

            <!-- Date -->
            <th class="sap" sortBy="date" *ngIf="!hideColumns.includes('date')">
              <span class="hidden-xs">{{ 'GLOBAL.ISSUED'|translate }}</span>
              <i class="material-symbols-rounded visible-xs">date_range</i>
            </th>

            <!-- Invoice Reference -->
            <th class="sap" sortBy="reference" *ngIf="!hideColumns.includes('reference')">
              {{ 'GLOBAL.INVOICE_NUMBER'|translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="left" [filters]="filters"
                key="filters[reference]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>

            <!-- Concept -->
            <th sortBy="concept.type" *ngIf="!hideColumns.includes('concept')">
              {{ 'GLOBAL.TYPE'|translate }}
            </th>

            <!-- Product -->
            <th sortBy="product_name" *ngIf="!hideColumns.includes('product_name')">
              {{ 'GLOBAL.PRODUCT'|translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="left" [filters]="filters"
                key="filters[items.*.contract.product.name]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            
            <!-- Emisor -->
            <th sortBy="company.name" *ngIf="!hideColumns.includes('company')">
              {{ 'GLOBAL.ISSUED_BY'|translate }}
            </th>

            <!-- Recipient -->
            <th sortBy="recipient.name" *ngIf="!hideColumns.includes('recipient')">
              {{ 'SLOTS.REQUEST_SLOT_MODAL.RECIPIENT'|translate }}
            </th>

            <!-- Due date -->
            <th class="sap" sortBy="due_date" *ngIf="!hideColumns.includes('due_date')">
              {{ 'GLOBAL.DUE_DATE'|translate }}
              <abbr title tooltip="{{ 'GLOBAL.DUE_DATE'|translate }}"
              [adaptivePosition]="false">{{ 'GLOBAL.DUE_DATE_ABBR'|translate }}</abbr>
            </th>

            <!-- Balance -->
            <th class="sap text-right" sortBy="balance" *ngIf="!hideColumns.includes('balance')">
              {{ 'GLOBAL.TOTAL'|translate }}
            </th>

            <!-- Download -->
            <th class="sap">
              <!-- {{"IMPORTER.SOURCE.2"|translate}} -->
              <column-filter *ngIf="!predefinedFilters" type="boolean" [filters]="filters" key="filters[attachments]"
                (onChange)="changeFilter($event)"></column-filter>
            </th>

            <!-- Source -->
            <!-- <th class="sap text-center" *ngIf="!hideColumns.includes('source')">
              <abbr tooltip="{{ 'GLOBAL.SOURCE'|translate }}" container="body" [adaptivePosition]="false">
                <span class="material-symbols-rounded">input</span>
              </abbr>
            </th> -->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let invoice of invoices|orderBy: tableSortable.sortBy;">
            <tr class="tr-align-center">
              <!-- Checkbox -->
              <td class="sap micro" rowspan="2" *ngIf="enableSelection">
                <input [AgCheckbox]="invoice" type="checkbox" class="show-onhover no-margin">
              </td>

              <!-- Date -->
              <td class="sap" *ngIf="!hideColumns.includes('date')">
                <date-md *ngIf="invoice.created_at; else none" [date]="invoice.created_at"></date-md>
              </td>

              <!-- Invoice reference -->
              <td class="sap" *ngIf="!hideColumns.includes('reference')"><samp>{{ invoice.id| mask: '0000-00000000' }}</samp></td>

              <!-- Type -->
              <td *ngIf="!hideColumns.includes('concept')">
                <ng-container *ngIf="invoice.type; else none">{{ invoice.type.name }}</ng-container>
              </td>

              <!-- Product -->
              <td rowspan="2" *ngIf="!hideColumns.includes('product_name')" class="small">
                <ng-container *ngIf="invoice.products?.length; else none">{{ invoice.products|listFormatter
                  }}</ng-container>
              </td>

              <!-- Emisor -->
              <td rowspan="2" *ngIf="!hideColumns.includes('company')"><company-info
                *ngIf="invoice.company; else none" [company]="invoice.company" [activity]="false"></company-info>
              </td>

            <!-- Recipiente -->
              <td rowspan="2" *ngIf="!hideColumns.includes('recipient')"><company-info
                  *ngIf="invoice.recipient; else none" [company]="invoice.recipient" [activity]="false"></company-info>
              </td>

              <!-- Due date -->
              <td class="sap small" rowspan="2" *ngIf="!hideColumns.includes('due_date')">
                <date-md [date]="invoice.due_date" [style]="true"></date-md>
              </td>

              <!-- Balance -->
              <td class="sap text-right" rowspan="2" *ngIf="!hideColumns.includes('balance')">
                <price [unit]="invoice.currency.name" [value]="invoice.total"></price>
              </td>

              <!-- Download -->
              <td class="sap">
                <button [disabled]="processing" title="{{ 'FILE_INPUT.PREVIEW'|translate }}"
                *ngIf="invoice.file_id" class="btn btn-link"
                (click)="viewFile(invoice)">
                  <span class="material-symbols-rounded">remove_red_eye</span>
                </button>
              </td>

              <!-- Source -->
              <!-- <td *ngIf="!hideColumns.includes('source')" class="sap" rowspan="2">
                <span *ngIf="invoice.import_channel_id; let source" class="micro text-muted">{{
                  'IMPORTER.SOURCE.'+source|translate }}</span>
              </td> -->
            </tr>
            <tr class="tr-align-center">
              <td [attr.colspan]="3 - hiddenColumnsCount(['date', 'reference', 'product_name'])"
                *ngIf="!hideColumns.includes('labels') && invoice.labels?.length" class="td-empty no-vertical-padding">
                <span *ngFor="let label of invoice.labels" class="ag-label" [style.backgroundColor]="label.color">{{
                  label.name }}</span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="no-data" *ngIf="invoices && invoices.length === 0">
      <i class="material-symbols-rounded">not_interested</i>
      {{ 'INVOICES.NO_DATA'|translate }}
    </div>
  </div>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>