import { Type } from "class-transformer";

import { Company } from "./company.model";
import { GeoSelection } from "./geo-selection.model";
import { Price } from "./price.model";
import { Product } from "./product.model";
import { Quantity } from "./quantity.model";

/**
 * Future or Option contract information.
 * 
 * ### Related UI components:
 * - [[FuturesTableComponent|futures-table]]
 * - [[AddFutureModalComponent|add-future-modal]]
 */
export class FutureOperation {

  /**
   * Date of the operation.
   */
  @Type(() => Date)
  date: Date;

  @Type(() => Date)
  position: Date;

  company: Company;
  /**
   * If there was a broker involved.
   */
  operator?: Company;
  price: Price;
  exchange_rate: Price;
  product: Product;
  volume: Quantity;
  delivery?: GeoSelection;

  condition: {
    /**
     * ### Possible values
     * 
     * | id | description |
     * |---:|-------------|
     * |  1 | Buy         |
     * |  2 | Sell        |
     */
    id: number
  };

  type: {
    /**
     * ### Possible values
     * 
     * | id | description |
     * |---:|-------------|
     * |  1 | Future      |
     * |  2 | Option      |
     */
    id: number;
  }

  /** Agree's internal unique identifier. */
  readonly id: number;
  observations?: string;
}
