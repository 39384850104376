import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImporterService {

  private importsStatusUrl: string = '/:apiBase/companies/:companyId/import-status';
  private importerUrl: string = '/:apiBase/companies/:companyId/import';

  constructor(
    private http: HttpClient
  ) { }

  public status(companyId: number): Observable<HttpResponse<any>> {
    const url = this.importsStatusUrl.replace(":companyId", companyId + '');

    return this.http.get<any>(url, { observe: 'response' });
  }

  public import(companyId: number, files: FileList): Observable<any[]> {
    const url = this.importerUrl.replace(":companyId", companyId + '');

    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append('files[]', files[i]);
    }

    return this.http.post<any[]>(url, data);
  }
}
