<future-modal #futureModal [company]="company"></future-modal>

<ag-add-contract-modal
  #contractModal
  [company]="company"
></ag-add-contract-modal>

<nav *ngIf="collections && company">
  <div class="tablist no-select" role="tablist">
    <ul class="inner">
      <li
        *ngFor="let collection of collections"
        class="tablist-tab"
        [class.active]="collection.id === selectedCollection?.id"
        (click)="navigateTo(collection.slug, collection.queryParams)"
        [innerHtml]="collection.title | translate"
      ></li>
    </ul>
  </div>
</nav>

<div class="ag-container">
  <div
    class="ag-col-secondary"
    [class.hidden]="
      !selectedCollection ||
      ['importer', 'waybills', 'cpe', 'liquidaciones'].includes(
        selectedCollection?.slug
      )
    "
  >
    <filter-set
      [presets]="['contracts'].includes(selectedCollection?.slug)"
      #filterSet="filterSet"
      [marketId]="company?.market.id"
    >
      <filter-list
        key="validity"
        sortKey="name"
        [class.hidden]="!['contracts'].includes(selectedCollection?.slug)"
      >
      </filter-list>
      <filter-list
        key="product_id"
        sortKey="name"
        [class.hidden]="
          ![
            'contracts',
            'unloads',
            'contract_applications',
            'invoices'
          ].includes(selectedCollection?.slug)
        "
      >
      </filter-list>
      <filter-list
        key="label_id"
        [class.hidden]="
          !['contracts'].includes(selectedCollection?.slug)
        "
      >
      </filter-list>
      <filter-list
        key="commercial_zone"
        sortKey="name"
        [class.hidden]="
          [
            'liquidaciones',
            'unloads',
            'contract_applications',
            'waybills',
            'invoices'
          ].includes(selectedCollection?.slug)
        "
      >
      </filter-list>
      <filter-date-range
        key="delivery_range"
        [class.hidden]="!['contracts'].includes(selectedCollection?.slug)"
      >
      </filter-date-range>
      <filter-date-range
        key="fixation_period"
        label="FILTERS.SELECT_PERIOD"
        [class.hidden]="!['contracts'].includes(selectedCollection?.slug)"
      ></filter-date-range>
      <filter-list
        key="price"
        [class.hidden]="!['contracts'].includes(selectedCollection?.slug)"
      ></filter-list>
      <filter-list
        key="crop"
        [class.hidden]="!['contracts'].includes(selectedCollection?.slug)"
      ></filter-list>
      <!-- <filter-select key="past_range" [class.hidden]="!['contracts'].includes(selectedCollection?.slug)"></filter-select> -->
      <filter-list
        key="differences"
        [class.hidden]="!['contracts'].includes(selectedCollection?.slug)"
      ></filter-list>
      <filter-list
        key="warnings"
        [class.hidden]="
          ![
            'contracts',
            'unloads',
            'contract_applications',
            'fixations'
          ].includes(selectedCollection?.slug)
        "
      >
      </filter-list>
    </filter-set>
  </div>
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <div class="ag-header sticky">
          <div class="ag-header-title">
            <div
              *ngIf="
                collectionResults &&
                selection?.length === 0 &&
                selectedCollection &&
                collectionResults[selectedCollection.slug]
              "
              class="summary"
            >
              <span
                *ngIf="selectedCollection.slug; let slug"
                [innerHtml]="
                  (slug === 'contracts'
                    ? 'CONTRACTS.SUMMARIZE'
                    : slug === 'liquidaciones'
                    ? 'LIQUIDACIONES.SUMMARIZE'
                    : slug === 'unloads'
                    ? 'TRUCKS.SUMMARIZE'
                    : slug === 'fixations'
                    ? 'FIXATIONS.SUMMARIZE'
                    : slug === 'waybills'
                    ? 'WAYBILLS.SUMMARIZE'
                    : slug === 'cpe'
                    ? 'CPE.SUMMARIZE'
                    : slug === 'invoices'
                    ? 'INVOICES.SUMMARIZE'
                    : slug === 'futures'
                    ? 'FUTURES.SUMMARIZE'
                    : ''
                  )
                    | translate
                      : {
                          quantity:
                            paginationData?.total ||
                              collectionResults[selectedCollection.slug]
                            | number : undefined : currentLang
                        }
                "
              ></span>
            </div>
            <div class="summary" *ngIf="selection?.length > 0">
              <span
                [innerHtml]="
                  'LABELS.SELECTED' | translate : { quantity: selection.length }
                "
              ></span>
              &mdash;
              <a class="small" (click)="selection = []">{{
                "GLOBAL.CLEAR_SELECTION" | translate
              }}</a>
              <ag-label-manager
                [(ngModel)]="selection"
                [company]="company"
              ></ag-label-manager>
            </div>
          </div>
          <div class="ag-header-actions" *ngIf="selectedCollection">
            <div class="btn-toolbar">
              <div class="btn-group">
                <button
                  *ngIf="exportableTable"
                  type="button"
                  class="btn btn-link"
                  (click)="exportableTable.exportToXLSX()"
                  [disabled]="exportableTable.exporting"
                >
                  <i class="material-symbols-rounded">save_alt</i>
                  <span class="hidden-xs">{{
                    "GLOBAL.EXPORT_XLS" | translate
                  }}</span>
                </button>
                <!-- Only available to Syngenta -->
                <ag-import-contracts
                *ngIf="syngentaImporter && selectedCollection?.slug === 'contracts'" 
                [company]="company"
                (submitted)="refresh()"
                entity="syngenta_contracts"></ag-import-contracts>
              </div>
              <div class="btn-group">
                <!-- Add buttons -->
                <button
                  *ngIf="isImporter && selectedCollection?.slug === 'contracts'"
                  type="button"
                  class="btn btn-agree"
                  (click)="contractModal.open()"
                  [disabled]="loading"
                >
                  <i class="material-symbols-rounded hidden-xs hidden-sm">add</i>
                  {{ "IMPORTER.ADD_CONTRACT" | translate }}
                </button>

                <button
                  *ngIf="selectedCollection?.slug === 'futures'"
                  type="button"
                  class="btn btn-agree"
                  (click)="futureModal.open()"
                  [disabled]="loading"
                >
                  {{ "FUTURES.ADD" | translate }}
                </button>
              </div>
              <div class="btn-group" *ngIf="paginationData?.total_pages > 1">
                <paginator
                  [data]="paginationData"
                  [loading]="loading"
                ></paginator>
              </div>
            </div>
          </div>
        </div>

        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
        </div>

        <div class="row" *ngIf="!loading && selectedCollection">
          <div class="col-xs-12">
            <contracts-table
              #contractTable
              *ngIf="selectedCollection.slug === 'contracts'"
              (load)="collectionLoaded('contracts', $event)"
              [(ngModel)]="selection"
              [enableSelection]="true"
              [selectedFilters]="selectedFilters"
              [company]="company"
            ></contracts-table>
            <ag-liquidaciones-table
              *ngIf="selectedCollection.slug === 'liquidaciones'"
              (load)="collectionLoaded('liquidaciones', $event)"
              [(ngModel)]="selection"
              [selectedFilters]="selectedFilters"
              [company]="company"
            ></ag-liquidaciones-table>
            <ag-contract-applications-table
              *ngIf="selectedCollection.slug === 'contract_applications'"
              (load)="collectionLoaded('contract_applications', $event)"
              [(ngModel)]="selection"
              [selectedFilters]="selectedFilters"
              [company]="company"
            ></ag-contract-applications-table>
            <invoices-table
              *ngIf="selectedCollection.slug === 'invoices'"
              (load)="collectionLoaded('invoices', $event)"
              [(ngModel)]="selection"
              [enableSelection]="true"
              [selectedFilters]="selectedFilters"
              [company]="company"
            >
            </invoices-table>
            <fixations-table
              *ngIf="selectedCollection.slug === 'fixations'"
              (load)="collectionLoaded('fixations', $event)"
              [(ngModel)]="selection"
              [selectedFilters]="selectedFilters"
              [showRequestedFixations]="true"
              [company]="company"
            ></fixations-table>
            <cpe-table
              *ngIf="selectedCollection.slug === 'cpe'"
              (load)="collectionLoaded('cpe', $event)"
              [(ngModel)]="selection"
              [selectedFilters]="selectedFilters"
              [company]="company"
            ></cpe-table>
            <futures-table
              *ngIf="selectedCollection.slug === 'futures'"
              (load)="collectionLoaded('futures', $event)"
              [company]="company"
            ></futures-table>
            <importer
              *ngIf="selectedCollection.slug === 'importer'"
              [company]="company"
            ></importer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
