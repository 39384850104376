import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Company } from '../../../../../models/company.model';
import { CompanyService } from '../../../../../services/company.service';
import { Currency } from '../../../../../models/currency.model';
import { FixationRequestService } from '../../services/fixation-request.service';
import { LoginService } from '../../../../../auth/services/login.service';
import { MarketService } from '../../../../../services/market.service';
import { Pagination } from '../../../../../models/pagination.model';
import { SubscriptionManager } from '../../../../../utilities/subscription-manager';
import { User } from '../../../../../auth/models/user.model';
import { FixationRequest } from '../../models/fixation-request.model';
import { ManageRequestFixationModalComponent } from '../manage-request-fixation-modal/manage-request-fixation-modal.component';

@Component({
  selector: 'fixation-request-table',
  templateUrl: './fixation-request-table.component.html',
  styleUrls: ['./fixation-request-table.component.scss']
})
export class FixationRequestTableComponent extends SubscriptionManager implements OnInit {

  @ViewChild('manageFixationRequestModal', { static: true }) private readonly manageFixationRequestModal: ManageRequestFixationModalComponent;

  @Input() public company: Company;
  @Input() public compact: boolean = false;

  @Output('load') readonly onLoad = new EventEmitter();

  public currencies: Currency[] = [];
  public selectedRequest: FixationRequest;
  public processing: boolean;
  public readonlyUser: boolean;
  /**
   * Current [[User]]
   */
  public user: User;
  public requests: FixationRequest[];

  constructor(
    private fixationRequestService: FixationRequestService,
    private marketService: MarketService,
    private loginService: LoginService,
    private companyService: CompanyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribePusher();
    this.getCurrentUser();
    this.getAccountPermissions();
    this.loadCurrencies();
    this.fetchRequests();
  }

  private subscribePusher(): void {
    this.subscriptions.push(this.fixationRequestService.fixationRequestChannel(this.company.id).subscribe(response => {
      this.fetchRequests();
    }));
  }

  private getCurrentUser(): void {
    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));
  }

  private getAccountPermissions(): void {
    this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
      if (account) {
        // Account changed
        this.readonlyUser = !account.is.logistics && !account.is.commercial && !account.is.commercial_with_users;
      }
    }));
  }

  private fetchRequests(): void {
    this.processing = true;
    this.fixationRequestService.getAll(this.company.id).subscribe(response => {
      this.requests = response;
      this.processing = false;
      // this.dataLoaded(response, parsePagination(response.headers));
    });
  }

  private loadCurrencies(): void {
    this.subscriptions.push(this.marketService.watchCurrencies().subscribe(currencies => {
      if (currencies) {
        this.currencies = currencies;
      }
    }));
  }

  public openManageFixationRequestModal(item: FixationRequest): void {
    this.selectedRequest = item;
    this.manageFixationRequestModal.show(item);
  }

  public isWaitingResponse(item: FixationRequest): boolean {
    const waitingCounterpartResponse =
      (item.status.name === 'waiting_company' && item.company.id !== this.company.id) ||
      (item.status.name === 'waiting_receipt' && item.recipient.id !== this.company.id);
    return waitingCounterpartResponse;
  }

  public isClosedRequest(item: FixationRequest): boolean {
    const isClosedRequest = item.status.name === 'accepted' || item.status.name === 'rejected'
    return isClosedRequest;
  }

  private dataLoaded(data: FixationRequest[], pagination?: Pagination): void {
    this.requests = data;
    this.processing = false;

    this.onLoad.emit({
      data: this.requests,
      pagination: pagination
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
