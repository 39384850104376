import { Component, Input } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Company } from '../../../../../models/company.model';
import { Currency } from '../../../../../models/currency.model';
import { Price } from '../../../../../models/price.model';
import { Quantity } from '../../../../../models/quantity.model';
import { Unit } from '../../../../../models/unit.model';
import { ModalManager } from '../../../../../utilities/modal-manager';
import { OrderService } from '../../../commercial/services/order.service';
import { FixationRequest } from '../../models/fixation-request.model';
import { FixationRequestService } from '../../services/fixation-request.service';

@Component({
  selector: 'manage-request-fixation-modal',
  templateUrl: './manage-request-fixation-modal.component.html',
  styleUrls: ['./manage-request-fixation-modal.component.scss']
})
export class ManageRequestFixationModalComponent extends ModalManager {

  @Input() public company: Company;
  /** [[Market]] supported [[Currency|Currencies]]. */
  @Input() public currencies: Currency[] = [];

  public request: FixationRequest;
  public initialRequest: FixationRequest;
  public processing: boolean = false;

  private linkedRequest: FixationRequest;

  constructor(
    public modalService: BsModalService,
    public fixationRequestService: FixationRequestService,
    public orderService: OrderService
  ) {
    super(modalService);
  }

  public initValuesIfNotExist(): void {
    if (!this.request?.quantity) {
      this.request.quantity = new Quantity({ value: null });
      this.request.quantity.unit = this.company.market.quantity_units[0] ?? new Unit();
    }

    if (!this.request?.price) {
      this.request.price = new Price({ unit: this.currencies[0], type: 'flat', value: null });
    }
  }

  get formIsComplete(): boolean {
    const sameQuantity = this.initialRequest.quantity?.value === this.request.quantity?.value;
    const samePrice = this.initialRequest.price?.value === this.request.price?.value;
    const sameCurrency = this.initialRequest.price?.unit.id === this.request.price?.unit.id;
    return sameQuantity && samePrice && sameCurrency;
  }

  public acceptRequest(): void {
    this.processing = true;
    this.subscriptions.push(
      this.fixationRequestService.accept(this.company.id, this.request.id).subscribe(response => {
        this.processing = false;
        this.closeModal();
      })
    )
  }

  public rejectRequest(): void {
    this.processing = true;
    this.subscriptions.push(
      this.fixationRequestService.reject(this.company.id, this.request.id).subscribe(response => {
        this.processing = false;
        this.closeModal();
      })
    )
  }

  public submitForm(): void {
    // Updatear el request
    this.processing = true;
    this.subscriptions.push(
      this.fixationRequestService.update(this.company.id, this.request).subscribe(response => {
        this.processing = false;
        this.linkedRequest = this.request;
        this.closeModal();
      })
    );
  }

  public show(item: FixationRequest): void {
    this.linkedRequest = item;
    this.initialRequest = instanceToInstance(item);
    this.request = instanceToInstance(item);
    this.request.observations = '';

    this.initValuesIfNotExist();
    this.openModal(this.modalTemplate, 'modal-lg');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
