import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { Currency } from '../../../models/currency.model';
import { FutureOperation } from '../../../models/future-operation.model';
import { Price } from '../../../models/price.model';
import { Product } from '../../../models/product.model';
import { Quantity } from '../../../models/quantity.model';
import { Unit } from '../../../models/unit.model';
import { CompanyService } from '../../../services/company.service';
import { CurrentDateService } from '../../../services/current-date.service';
import { HubSpotService } from '../../../services/hub-spot.service';
import { MarketService } from '../../../services/market.service';

/**
 * AddFutureModal is a component that can be used to add or edit
 * [[FutureOperation|futures or options operations]].
 * 
 * ## Usage
 * ``` html
 * <future-modal #futureModal
 * [company]="company"></future-modal>
 * ```
 * 
 * Then you can open the modal as follows:
 * 
 * ``` html
 * <button type="button"
 * (click)="futureModal.open()">Open Future modal</button>
 * ```
 * 
 * ### Related UI components:
 * - [[FuturesTableComponent|futures-table]]
 */
@Component({
  selector: 'future-modal',
  templateUrl: './add-future-modal.component.html',
  styleUrls: ['./add-future-modal.component.scss']
})
export class AddFutureModalComponent implements OnInit, OnDestroy {

  @ViewChild('modalTemplate', { static: true }) private readonly modalTemplate: TemplateRef<any>;

  @Input() public company: Company;

  /** [[Market]] supported [[Currency|Currencies]]. */
  public currencies: Currency[] = [];
  /** @ignore */
  public modalRef: BsModalRef;
  /** @ignore */
  public operation: FutureOperation;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  /** @ignore */
  public products: Product[];
  /** @ignore */
  public today: Date = this.currentDate.get();
  /** @ignore */
  public unit_ar: Currency;
  /** @ignore */
  public unit_us: Currency;

  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private currentDate: CurrentDateService,
    private modalService: BsModalService,
    private marketService: MarketService,
    public hubSpotService: HubSpotService,
    /** @ignore */
    public companyService: CompanyService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.subscriptions.push(this.marketService.watchProducts().subscribe(response => {
      if (response) this.products = response.sort((a, b) => a.name.localeCompare(b.name)); // Sorts alphabetically
    }));
    this.subscriptions.push(this.marketService.watchCurrencies().subscribe(response => {
      if (response) {
        this.currencies = response;

        this.unit_ar = this.currencies.find(unit => unit.id === 1); // $AR
        this.unit_us = this.currencies.find(unit => unit.id === 2); // USD
      }
    }));
  }

  /**
   * Opens the component's modal.
   * 
   * @param operation If no operation is specified, a new one is created.
   */
  public open(operation?: FutureOperation): void {
    this.operation = operation || this.newOperation();
    this.openModal(this.modalTemplate, 'modal-lg');
  }

  private newOperation(): FutureOperation {
    // Generates a new default operation
    let vl = new Quantity(),
      pr = new Price(),
      er = new Price(),
      tn = new Unit();

    tn = this.company.market.quantity_units.find(unit => unit.id === 1); // Tons

    vl.type = { id: 1, name: "Fija", slug: "fixed" };
    vl.unit = tn;

    pr.type = "flat";
    pr.quantity_unit = tn;
    pr.unit = this.unit_us;

    er.type = "flat";
    er.unit = this.unit_ar;

    let op = new FutureOperation();
    op.date = this.currentDate.get();
    op.position = this.currentDate.get();
    op.operator = this.company;
    op.type = { id: 1 };
    op.condition = { id: 1 };
    op.volume = vl;
    op.price = pr;
    op.exchange_rate = er;

    return op;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });
  }

  /** 
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: string | number }, b: { id: string | number }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  /** @ignore */
  public submit(): void {
    this.processing = true;
    // this.modalRef.hide();
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
