import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { FilePreviewComponent } from '../../../../../ui/components/file-preview/file-preview.component';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { removeString } from '../../../../../utilities/array';
import { TableFilters } from '../../../../../utilities/table-filters';
import { Invoice } from '../../models/invoice.model';
import { InvoiceV2 } from '../../models/invoicev2.model';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrls: ['./invoices-table.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InvoicesTableComponent),
    multi: true
  }]
})
export class InvoicesTableComponent extends TableFilters implements OnInit, ControlValueAccessor, OnDestroy {

  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;

  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = [];
  @Input() public selectedFilters: FilterSetComponent;
  /** Whether the selection checkboxes are shown or not. */
  @Input() public enableSelection: boolean;

  @Output('load') readonly onLoad = new EventEmitter();

  public invoices: InvoiceV2[];
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public predefinedFilters: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
  */
  public processing: boolean;
  public isImporter: boolean;

  private pusherSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private invoiceService: InvoiceService
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    const { configuration } = this.company.market;
    if (configuration.imported_data.collections &&
      configuration.imported_data.collections.includes('invoices')) {
      if (this.filters) {
        this.predefinedFilters = true;
        this.loadData();
      } else {
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }

    this.isImporter = this.company.hasModule('importer');
  }

  private loadData(): void {
    this.loading = true;
    this.selected = [];

    if (!this.predefinedFilters) {
      if (this.route.parent.snapshot.queryParams['product_id']) this.hideColumns.push('product');
      else this.hideColumns = removeString(this.hideColumns, 'product');
    }

    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.invoiceService.watchV2(this.company.id, this.filters).subscribe(response => {
      this.dataLoaded(response.body, parsePagination(response.headers));
    });
  }

  private dataLoaded(data: InvoiceV2[], pagination: Pagination): void {
    this.invoices = data;
    this.loading = false;

    this.onLoad.emit({
      data: this.invoices,
      pagination: pagination
    });
  }

  public viewFile(invoice: InvoiceV2): void {
    this.processing = true;
    const observable = this.invoiceService.getFileById(this.company.id, invoice);

    this.subscriptions.push(observable.subscribe(response => {
      this.processing = false;
      this.filePreviewer.preview(observable, response.filename, response.size, response.url);
    }));
  }

  public hiddenColumnsCount(columns: string[]): number {
    let count: number = 0;
    columns.forEach(key => {
      if (this.hideColumns.includes(key)) count++;
    });
    return count;
  }

  // ngModel
  private _value: Invoice[] = [];
  public get selected(): Invoice[] { return this._value }
  public set selected(v: Invoice[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }
}
