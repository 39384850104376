<contract-modal #contractModal [company]="company"></contract-modal>

<cpe-modal #cpeModal [company]="company"></cpe-modal>

<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
</div>
<ng-container *ngIf="!loading">
  <div class="horizontal-scroller">
    <div class="table-sticky-headers">
      <table class="table table-condensed" sortable #tableSortable="tableSortable" [variableInUrl]="!predefinedFilters"
        AgMultiselection [(ngModel)]="selected">
        <thead>
          <tr class="tr-align-center">
            <th class="sap micro" *ngIf="enableSelection &&
              applications?.length > 0">
              <input type="checkbox" AgSelectall
              id="select-all-toggle"
              class="show-onhover no-margin"
              tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
              container="body" [adaptivePosition]="false">
            </th>
            <th class="sap" sortBy="date" *ngIf="!hideColumns.includes('date')">
              <span class="hidden-xs">{{ 'GLOBAL.DATE'|translate }}</span>
              <i class="material-symbols-rounded visible-xs">date_range</i>
            </th>
            <th class="sap" sortBy="cpe.ctg" *ngIf="!hideColumns.includes('numero_ctg')">
              <abbr [tooltip]="'SLOTS.DETAILS_MODAL.CTG' | translate" title [adaptivePosition]="false">{{
                "SLOTS.DETAILS_MODAL.CTG_ABBR" | translate }}</abbr>

              <column-filter *ngIf="!predefinedFilters" type="string" alignment="left" [filters]="filters"
                key="filters[cpe.ctg]" (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th class="sap" sortBy="contract.reference" *ngIf="!hideColumns.includes('contract_reference')">
              {{ 'GLOBAL.CONTRACT'|translate }}

              <column-filter *ngIf="!predefinedFilters" type="string" alignment="left" [filters]="filters"
                key="filters[contract.reference]" (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th class="sap"></th>
            <th *ngIf="!hideColumns.includes('product_name')">
              {{ 'GLOBAL.PRODUCT'|translate }}
            </th>
            <th sortBy="contract.seller">
              {{"ORDER_SUMMARY.SELLER"|translate}}
              <column-filter *ngIf="!predefinedFilters" type="string" alignment="left" [filters]="filters"
                key="filters[seller.cuit]" (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th sortBy="contract.buyer">
              {{"ORDER_SUMMARY.BUYER"|translate}}
              <column-filter *ngIf="!predefinedFilters" type="string" alignment="left" [filters]="filters"
                key="filters[buyer.cuit]" (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th *ngIf="!hideColumns.includes('company')">
              {{ 'CONTRACTS.INFORMANT'|translate }}
            </th>
            <th class="sap text-right" *ngIf="!hideColumns.includes('quantity')">{{
              'TRUCKS.APPLIED'|translate }}</th>
            <th class="sap text-center" *ngIf="!hideColumns.includes('source')">
              <abbr tooltip="{{ 'GLOBAL.SOURCE'|translate }}" container="body" [adaptivePosition]="false">
                <span class="material-symbols-rounded">input</span>
              </abbr>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let application of applications|orderBy: tableSortable.sortBy;">
            <tr class="tr-align-center">
              <td class="sap micro" *ngIf="enableSelection">
                <input [AgCheckbox]="application" type="checkbox" class="show-onhover no-margin">
              </td>
              <td class="sap small" *ngIf="!hideColumns.includes('date')">
                <date-md *ngIf="application.date; else none" [date]="application.date" [showTime]="false" timezone="UTC"></date-md>
              </td>
              <td class="sap small" *ngIf="!hideColumns.includes('numero_ctg')">
                <samp><a (click)="cpeModal.show(application.numero_ctg)">{{ application.numero_ctg }}</a></samp>
              </td>
              <td class="sap small" *ngIf="!hideColumns.includes('contract_reference')">
                <samp *ngIf="application.contract?.reference; else none"><a
                    (click)="contractModal.show(application.contract.id)">{{ application.contract.reference
                    }}</a></samp>
              </td>
              <td class="sap">
                <span *ngIf="application.warnings?.length"
                  [tooltip]="application.warnings?.length ? warnTemplate : null" [adaptivePosition]="false"
                  class="differences material-symbols-rounded text-warning no-select hidden-xs">warning</span>
                <ng-template #warnTemplate>
                  <div class="text-left">
                    <p class="small">{{ 'IMPORTER.WARNING.TITLE'|translate }}</p>
                    <span *ngFor="let d of application.warnings; let last = last">
                      {{ 'IMPORTER.WARNING.'+d|translate }}
                      <ng-container *ngIf="!last"> ,</ng-container>
                    </span>
                  </div>
                </ng-template>
              </td>
              <td *ngIf="!hideColumns.includes('product_name')">
                <ng-container *ngIf="application.product; else none">{{ application.product.name }}</ng-container>
              </td>
              <td>
                <company-info [activity]="false" [company]="application.contract.seller"></company-info>
              </td>
              <td>
                <company-info [activity]="false" [company]="application.contract.buyer"></company-info>
              </td>
              <td>
                <company-info [company]="application.company" [activity]="false"></company-info>
              </td>
              <td class="sap text-right" *ngIf="!hideColumns.includes('quantity')">
                {{ application.quantity.value|number:'1.0-3':currentLang }} <small class="text-muted">{{
                  application.quantity.unit.abbrev }}</small>
              </td>
              <td *ngIf="!hideColumns.includes('source')" class="sap">
                <span *ngIf="application.import_channel_id; let source" class="micro text-muted">{{
                  'IMPORTER.SOURCE.'+source|translate }}</span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="no-data" *ngIf="applications && applications.length === 0">
      <i class="material-symbols-rounded">not_interested</i>
      {{ 'GLOBAL.NO_DATA'|translate }}
    </div>
  </div>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>
