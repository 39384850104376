<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'FIXATIONS.REQUEST_MODAL.MANAGE'|translate }}</h4>
  </div>

  <div class="modal-body">
    <div class="loading" *ngIf="loading && !error">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>

    <alert *ngIf="initialRequest?.observations" type="info">
      <label class="control-label">{{ 'FIXATIONS.REQUEST_MODAL.LAST_OBSERBATION'|translate }}</label>
      {{ initialRequest?.observations }}
    </alert>
    <form *ngIf="request" id="requestFixationForm" #requestFixationForm="ngForm">
      <div class="form-group" hasError>
        <div class="row">
          <div class="col-xs-6">
            <div class="input-item">
              <label class="control-label">{{ 'GLOBAL.PRICE'|translate }}</label>
              <price-input
                [(price)]="request.price"
                [currencies]="currencies"
                [disabled]="processing"
                placeholder="0.00">
              </price-input>
            </div>

          </div>
          <div class="col-xs-6">
            <div class="input-item">
              <label class="control-label">{{ 'GLOBAL.QUANTITY'|translate }}</label>
              <quantity-input
                [company]="company"
                [(quantity)]="request.quantity"
                [disabled]="processing"
                placeholder="0">
              </quantity-input>
            </div>
          </div>
        </div>

        <div class="row input-item">
          <div class="col-xs-12">
            <div class="form-group">
              <label class="control-label">{{ 'GLOBAL.OBSERVATIONS'|translate }}
                <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
              </label>
              <textarea class="form-control" name="observations" [disabled]="processing"
                [(ngModel)]="request.observations" maxlength="250" rows="4"></textarea>
              <p class="help-block small">{{ 'FIXATIONS.REQUEST_MODAL.OBSERVATIONS_HELP'|translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </form>

    <trackrecord entity="fixation_request" [ids]="[request.id]"></trackrecord>

    <div class="no-data" *ngIf="error">
      <i class="material-symbols-rounded text-danger">error_outline</i>
      <span [innerHtml]="'GLOBAL.DATA_ERROR_PERMISSION'|translate"></span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button type="button"
    class="btn btn-danger" [disabled]="processing" (onConfirm)="rejectRequest()">
      {{ 'GLOBAL.REJECT' | translate }}
      <i class="material-symbols-rounded" style="margin-left: 5px;">thumb_down</i>
    </button>
    <button type="button"
    *ngIf="!formIsComplete; else completeRequest" class="btn btn-agree" [disabled]="processing" (click)="submitForm()">
      {{ 'MANAGERS.REQUEST.SEND' | translate  }}
      <i class="material-symbols-rounded" style="margin-left: 5px;">send</i>
    </button>

    <ng-template #completeRequest>
      <button type="button"
      class="btn btn-agree" [disabled]="processing" (onConfirm)="acceptRequest()">
        {{ 'GLOBAL.ACCEPT' | translate }}
        <i class="material-symbols-rounded" style="margin-left: 5px;">thumb_up</i>
      </button>

    </ng-template>

  </div>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>
