import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { Pagination, parsePagination } from "../../../../../models/pagination.model";
import { FilePreviewComponent } from "../../../../../ui/components/file-preview/file-preview.component";
import { FilterSetComponent } from "../../../../../ui/components/filter-set/filter-set.component";
import { TableFilters } from "../../../../../utilities/table-filters";
import { CartaPorteElectronica } from "../../models/carta-porte-electronica.model";
import { CartaPorteElectronicaService } from "../../services/carta-porte-electronica.service";

@Component({
  selector: "cpe-table",
  templateUrl: "./cpe-table.component.html",
  styleUrls: ["./cpe-table.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CpeTableComponent),
      multi: true,
    },
  ],
})
export class CpeTableComponent extends TableFilters implements OnInit, OnDestroy, ControlValueAccessor {

  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;

  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = [];
  @Input() public selectedFilters: FilterSetComponent;
  /** Whether the selection checkboxes are shown or not. */
  @Input() public enableSelection: boolean;

  @Output("load") readonly onLoad = new EventEmitter();

  public cartasDePorteElectronicas: CartaPorteElectronica[];
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public predefinedFilters: boolean;

  constructor(
    private cpeService: CartaPorteElectronicaService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(route, router);
    // this.loading = true;
  }

  ngOnInit(): void {
    const { configuration } = this.company.market;

    if ((configuration.imported_data.collections &&
      configuration.imported_data.collections.includes("cpe"))) {
      if (this.filters) {
        this.predefinedFilters = true;
        this.loadData();
      } else {
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }
  }

  private loadData(): void {
    this.loading = true;
    this.selected = [];

    this.subscriptions.push(this.cpeService.get(this.company.id, this.filters).subscribe(response => {
      this.dataLoaded(response.body, parsePagination(response.headers));
    }));
  }

  private dataLoaded(data: CartaPorteElectronica[], pagination?: Pagination): void {
    this.cartasDePorteElectronicas = data || [];
    this.loading = false;

    this.onLoad.emit({
      data: this.cartasDePorteElectronicas,
      pagination: pagination
    });
  }

  public preview(cpeItem: CartaPorteElectronica): void {
    // After fix the extension of the file in the api service, get the real file name from file download
    const fileNameProvisional = cpeItem.numero_ctg + ".pdf";
    this.filePreviewer.preview(this.cpeService.getFile(this.company.id, cpeItem.numero_ctg), fileNameProvisional);
  }

  // ngModel
  private _value: CartaPorteElectronica[] = [];
  public get selected(): CartaPorteElectronica[] {
    return this._value;
  }
  public set selected(v: CartaPorteElectronica[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
