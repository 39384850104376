<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'FUTURES.ADD'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="loading" *ngIf="!operation">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>

    <form id="future-form" #futureForm="ngForm"
    (ngSubmit)="futureForm.valid && submit()"
    [class.hidden]="!operation">
      <ng-container *ngIf="operation">
        <div class="row">
          <div class="col-sm-6"><!-- Type -->
            <div class="form-group" hasError>
              <label for="operationType" class="control-label">{{ 'GLOBAL.TYPE'|translate }}</label>
              <div class="btn-group btn-group-agree btn-group-justified" data-toggle="buttons">
                <label class="btn btn-default" [class.active]="operation.type.id === 1">
                  <input type="radio" name="operationType" id="order-type-1" [(ngModel)]="operation.type.id"
                  [value]="1" required>
                  <span>{{ 'FUTURES.TYPES.1'|translate }}</span>
                </label>
                <label class="btn btn-default" [class.active]="operation.type.id === 2">
                  <input type="radio" name="operationType" id="order-type-2" [(ngModel)]="operation.type.id"
                  [value]="2">
                  <span>{{ 'FUTURES.TYPES.2'|translate }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-sm-4"><!-- Date -->
            <div class="form-group" hasError>
              <label class="control-label">{{ 'FUTURES.OPERATION_DATE'|translate }}</label>
              <input type="text" name="date" class="form-control" bsDatepicker
              placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}"
              required
              [bsConfig]="{showWeekNumbers: false, adaptivePosition: true, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
              [maxDate]="today"
              [(ngModel)]="operation.date"
              readonly>
              <error-list></error-list>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6"><!-- Company -->
            <div class="form-group" hasError>
              <label for="company" class="control-label">{{ 'GLOBAL.COMPANY'|translate }}</label>
              <ng-container *ngIf="!operation.company; else selectedCompany">
                <selectize
                #selectize="selectize"
                required
                [maxItems]="1"
                [searchURL]="companyService.getCompanies(company.id)"
                [(ngModel)]="operation.company"
                name="company"
                placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
                [disabled]="processing"></selectize>
                <a class="small" (click)="operation.company = company">{{ 'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
              </ng-container>
              <ng-template #selectedCompany>
                <div class="editable-pill">
                  <button type="button"
                  [disabled]="processing" class="close" (click)="operation.company = undefined"><span aria-hidden="true">&times;</span></button>
                  <company-info [company]="operation.company"></company-info>
                </div>  
              </ng-template>
            </div>
          </div>
          <div class="col-sm-6"><!-- Operator -->
            <div class="form-group" hasError>
              <label for="operator" class="control-label">
                {{ 'FUTURES.OPERATOR'|translate }}
                <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
              </label>
              <ng-container *ngIf="!operation.operator; else selectedOperator">
                <selectize
                #selectize="selectize"
                *ngIf="!operation.operator; else selectedOperator"
                [maxItems]="1"
                [searchURL]="companyService.getCompanies(company.id)"
                [(ngModel)]="operation.operator"
                name="operator"
                placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
                [disabled]="processing"></selectize>
                <a class="small" (click)="operation.operator = company">{{ 'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
              </ng-container>
              <ng-template #selectedOperator>
                <div class="editable-pill">
                  <button type="button"
                  [disabled]="processing" class="close" (click)="operation.operator = undefined"><span aria-hidden="true">&times;</span></button>
                  <company-info [company]="operation.operator"></company-info>
                </div>  
              </ng-template>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6"><!-- Condition -->
            <div class="form-group" hasError>
              <label for="operationCondition" class="control-label">{{ 'FUTURES.CONDITION'|translate }}</label>
              <div class="btn-group btn-group-agree btn-group-justified" data-toggle="buttons">
                <label class="btn btn-default" [class.active]="operation.condition.id === 1">
                  <input type="radio" name="operationCondition" id="order-type-1" [(ngModel)]="operation.condition.id"
                  [value]="1" required>
                  <span>{{ 'GLOBAL.BID'|translate }}</span>
                </label>
                <label class="btn btn-default" [class.active]="operation.condition.id === 2">
                  <input type="radio" name="operationCondition" id="order-type-2" [(ngModel)]="operation.condition.id"
                  [value]="2">
                  <span>{{ 'GLOBAL.SELL'|translate }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4"><!-- Product -->
            <div class="form-group" hasError>
              <label for="product" class="control-label">{{ 'GLOBAL.PRODUCT'|translate }}</label>
              <select class="form-control" name="product" [ngModel]="operation.product"
              required [compareWith]="compareId" [disabled]="processing">
                <option [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'GLOBAL.SELECT_PRODUCT'|translate }}</option>
                <option *ngFor="let prod of [
                  {id: 1, name: 'Soja'},
                  {id: 2, name: 'Maíz'},
                  {id: 3, name: 'Trigo Pan'}
                ]" [ngValue]="prod">{{ prod.name }}</option>
              </select>
              <error-list></error-list>
              <a class="small"
              (click)="hubSpotService.open()">{{ 'ORDER_FORM.PRODUCT.NOT_LISTED'|translate }}</a>
            </div>
          </div>
          <div class="col-sm-4"><!-- Position -->
            <div class="form-group" hasError>
              <label for="position" class="control-label">{{ 'FUTURES.POSITION'|translate }}</label>
              <input type="text" name="position" class="form-control" bsDatepicker
              placeholder="{{ 'FUTURES.POSITION_PLACEHOLDER'|translate }}"
              required
              [bsConfig]="{showWeekNumbers: false, adaptivePosition: true, isAnimated: true, dateInputFormat: 'MM/YYYY'}"
              minMode="month"
              [minDate]="today"
              [(ngModel)]="operation.position"
              readonly>
              <error-list></error-list>
            </div>
          </div>
          <div class="col-sm-4"><!-- Volume -->
            <div class="form-group" hasError>
              <label class="control-label">{{ 'FUTURES.VOLUME'|translate }}</label>
              <div class="input-group">
                <input type="number" placeholder="0" step="1" class="form-control" name="quantity" [(ngModel)]="operation.volume.value"
                #quantity="ngModel" required number [min]="1"
                [max]="9999999">
                <div dropdown agSelect name="quantityUnit" class="input-group-btn" [(ngModel)]="operation.volume.unit"
                [class.single-unit]="company.market.quantity_units.length <= 1">
                  <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                    {{ operation.volume?.unit.name_plural || operation.volume?.unit.abbrev || operation.volume?.unit.name }}
                    <span class="caret"></span>
                  </button>
                  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let u of company.market.quantity_units" [agOption]="u"><a>{{ u.name_plural }}</a></li>
                  </ul>
                </div>
              </div><!-- /btn-group -->    
              <error-list></error-list>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4"><!-- Price -->
            <div ngModelGroup="priceGroup" hasError>
              <div class="form-group" hasError>
                <label class="control-label">{{ 'GLOBAL.PRICE'|translate }}</label>
                <price-form
                [types]="[{id: 'flat', label: 'Fijo', enabled: true}]"
                [price]="operation.price"
                [currencies]="[this.unit_us]"
                [min]="0.01"
                [units]="[operation.volume.unit]"
                placeholder="0.00"></price-form>
                <error-list></error-list>
              </div>
            </div>
          </div>
          <div class="col-sm-8"><!-- Delivery place -->
            <div class="form-group" hasError *ngIf="company.market.configuration.location?.enabled">
              <label for="localidad" class="control-label">
                {{ 'MARKET_SUMMARY.DELIVERY_LOCATION'|translate }}
              </label>
              <div class="clear-input">
                <button *ngIf="operation.delivery" type="button" class="clear-button"
                title="{{ 'GLOBAL.CLEAR'|translate }}"
                [disabled]="processing"
                (click)="operation.delivery = undefined">
                  <span class="material-symbols-rounded">backspace</span>
                </button>            
                <input type="text" name="localidad" class="form-control not-grayed"
                placeholder="{{ 'FUTURE.NO_DELIVERY'|translate }}"
                value="{{ operation.delivery ? ([operation.delivery]|locationString) : ('FUTURES.NO_DELIVERY'|translate) }}"
                (click)="locationPicker.show()"
                readonly>
              </div>
              <ag-location-picker
              name="localidadpicker"
              [(ngModel)]="operation.delivery"
              [market]="company.market"
              [arrayMinlength]="0"
              [hidden]="true"
              #locationPicker="agLocationPicker"></ag-location-picker>
              <error-list></error-list>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4"><!-- Exchange rate -->
            <div ngModelGroup="priceGroup" hasError>
              <div class="form-group" hasError>
                <label class="control-label">{{ 'FUTURES.EXCHANGE_RATE'|translate }}</label>
                <price-form
                prefix="er_"
                [types]="[{id: 'flat', label: 'Fijo', enabled: true}]"
                [price]="operation.exchange_rate"
                [currencies]="[this.unit_ar]"
                [showQuantityUnit]="false"
                [min]="0.01"
                placeholder="0.00"></price-form>
                <error-list></error-list>
                <p class="help-block small" [innerHtml]="'FUTURES.EXCHANGE_RATE_HELP'|translate"></p>
              </div>
            </div>
          </div>
          <div class="col-sm-8"><!-- Observations -->
            <div class="form-group" hasError>
              <label class="control-label">{{ 'GLOBAL.OBSERVATIONS'|translate }}
                <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
              </label>
              <textarea class="form-control" rows="3" name="obs"
              maxlength="512"
              [(ngModel)]="operation.observations"></textarea>
            </div>
          </div>
        </div>
      </ng-container>
    </form>  
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" [disabled]="processing" type="button" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="future-form" class="btn btn-agree" [disabled]="!operation || processing">
      {{ 'FUTURES.ADD'|translate }}
    </button>
  </div>
</ng-template>