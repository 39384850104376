import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { TableFilters } from '../../../../../utilities/table-filters';
import { ContractService } from '../../../contracts/services/contract.service';
import { ContractApplication } from '../../models/contract-application.model';

@Component({
  selector: 'ag-contract-applications-table',
  templateUrl: './contract-applications-table.component.html',
  styleUrls: ['./contract-applications-table.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ContractApplicationsTableComponent),
    multi: true
  }]
})
export class ContractApplicationsTableComponent extends TableFilters implements OnInit, ControlValueAccessor, OnDestroy {

  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = [];
  @Input() public selectedFilters: FilterSetComponent;
  /** Whether the selection checkboxes are shown or not. */
  @Input() public enableSelection: boolean;

  @Output('load') readonly onLoad = new EventEmitter();

  public applications: ContractApplication[];
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public predefinedFilters: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
  */
  public processing: boolean;

  private pusherSubscription: Subscription;

  constructor(
    private contractService: ContractService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    const { configuration } = this.company.market;
    if (configuration.imported_data.collections &&
      configuration.imported_data.collections.includes('contract_applications')) {
      if (this.filters) {
        this.predefinedFilters = true;
        this.loadData();
      } else {
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }
  }

  private loadData(): void {
    this.loading = true;
    this.selected = [];

    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.contractService.watchAplicaciones(this.company.id, this.route.parent.snapshot.queryParams).subscribe(response => {
      this.dataLoaded(response.body, parsePagination(response.headers));
    });
  }

  private dataLoaded(data: ContractApplication[], pagination: Pagination): void {
    this.applications = data;
    this.loading = false;

    this.onLoad.emit({
      data: this.applications,
      pagination: pagination
    });
  }

  // ngModel
  private _value: ContractApplication[] = [];
  public get selected(): ContractApplication[] { return this._value }
  public set selected(v: ContractApplication[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }
}
